@use 'node_modules/@ngneat/hot-toast/src/styles/styles.scss';
@import '~@angular/cdk/overlay-prebuilt.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Raleway';
  src: url(/assets/fonts/Raleway-Regular.ttf) format('truetype');
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: 'Raleway';
  src: url(/assets/fonts/Raleway-Bold.ttf) format('truetype');
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: 'Merriweather';
  src: url(/assets/fonts/Merriweather-Bold.ttf) format('truetype');
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: 'Merriweather';
  src: url(/assets/fonts/Merriweather-Regular.ttf) format('truetype');
  font-style: normal;
  font-weight: 400;
}

html,
body {
  min-height: 100%;
  height: 100%;
}

* {
  font-family: Raleway, sans-serif;
}

a {
  text-decoration: underline;
  cursor: pointer;
}

img {
  image-rendering: crisp-edges;
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
}

.post-content {
  p {
    padding-bottom: 20px;
  }

  h3 {
    padding: 10px 20px;
    font-size: 20px;
    font-family: Merriweather, serif;
    text-align: center;
    color: #1b6584;
  }

  img {
    border-radius: 8px;
    margin: 10px auto;
  }
}

.custom-overlay-backdrop {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.custom-overlay-panel {
  display: block;
}

.slide-transition {
  &--position {
    transition: left 0.2s ease;
  }
  &--color {
    transition: color 0.15s ease;
  }
}

.gradient-border {
  border: none;

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    border-radius: 20px;
    padding: 2px;
    background: linear-gradient(135deg, rgba(233, 115, 71, 1), rgba(92, 62, 241, 1));
    -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    z-index: 2;
  }
}

.custom-list-styles {
  ul,
  ol {
    padding-inline-start: 40px;
  }
  ul {
    list-style-type: disc;
  }
  ol {
    list-style-type: decimal;
  }
  .angular-editor-textarea {
    background-color: #fff !important;
  }
}
